import React from "react"

class Portfolio extends React.Component {
  componentDidMount() {
    window.location.replace(
      "https://www.notion.so/uduwela/17f2affc87ea43a58efe52113d34f94c?v=9d5f2efbf1dc4b4a92ade63d3541f75b"
    )
  }

  render() {
    return null
  }
}

export default Portfolio
